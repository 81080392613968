import React from "react";
import "./assets/style.css";
import ProfilePic from "./assets/images/ProfilePicCropped.jpeg";

export default function App() {
  function handlePointerMove(e) {
    let underline = e.target.nextSibling;
    underline.style.display = "block";
    underline.style.opacity = "1";

    function moveUnderline() {
      underline.style.transition = "all 0ms ease-in-out";
      underline.style.transform = `translate(calc((${
        e.pageX - e.target.offsetLeft
      }px - 50%) / 5), calc((${
        e.pageY - e.target.offsetTop - 10
      }px - 50%) / 5))`;
    }
    function returnUnderline() {
      underline.style.transition = "all 200ms ease-in-out";
      underline.style.transform = "translate(0)";
    }
    setTimeout(returnUnderline, 100);
    moveUnderline();
  }
  function handleMouseEnter(e) {
    let underline = e.target.nextSibling;
    underline.style.display = "block";
    underline.style.opacity = "1";
  }
  function handleMouseOut(e) {
    let underline = e.target.nextSibling;
    underline.style.opacity = "0";
    setTimeout(() => (underline.style.display = "none"), 350);
    setTimeout(() => (underline.style.opacity = "1"), 350);
  }

  return (
    <div>
      <section id="Intro">
        <img className="profile-picture" src={ProfilePic} alt="Tim Ponce"></img>
        <div className="intro--info">
          <h1 id="intro--heading">TIM PONCE</h1>
          <h4 id="intro--subheading">Software Engineer</h4>
          <nav className="intro--nav">
            <ul className="page-links">
              <li className="page-link">
                <a
                  href="#About"
                  className="internal-link"
                  onMouseEnter={(e) => handleMouseEnter(e)}
                  onMouseOut={(e) => handleMouseOut(e)}
                  onPointerMove={(e) => handlePointerMove(e)}
                >
                  ABOUT
                </a>
                <div className="link-underline"></div>
              </li>
              <li className="page-link">
                <a
                  href="#Projects"
                  className="internal-link"
                  onMouseEnter={(e) => handleMouseEnter(e)}
                  onMouseOut={(e) => handleMouseOut(e)}
                  onPointerMove={(e) => handlePointerMove(e)}
                >
                  PROJECTS
                </a>
                <div className="link-underline"></div>
              </li>
              <li className="page-link">
                <a
                  href="#Contact"
                  className="internal-link"
                  onMouseEnter={(e) => handleMouseEnter(e)}
                  onMouseOut={(e) => handleMouseOut(e)}
                  onPointerMove={(e) => handlePointerMove(e)}
                >
                  CONTACT
                </a>
                <div className="link-underline"></div>
              </li>
            </ul>
          </nav>
        </div>
      </section>
      <section id="About">
        <p className="large-text">
          Hello, my name is Tim. I am a self-taught frontend engineer. I am a
          recent graduate from UC Santa Barbara who found a passion for web
          development and all the problem solving that comes with it. In my free
          time, I like working on fun personal projects, backpacking, and
          gaming.
        </p>
      </section>
      <section id="Projects">Projects</section>
      <section id="Contact">
        <h2 className="contact--heading">Contact Me</h2>
        <form className="contact--form" onSubmit={(e) => e.preventDefault()}>
          <div className="form-row">
            <div className="form-field">
              <label className="form-label" htmlFor="name">
                NAME<span className="form-required">*</span>
              </label>
              <input className="form-input" name="name" />
            </div>
            <div className="form-field">
              <label className="form-label" htmlFor="email">
                EMAIL<span className="form-required">*</span>
              </label>

              <input className="form-input" name="email" type="email" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-field">
              <label className="form-label" htmlFor="subject">
                SUBJECT<span className="form-required">*</span>
              </label>
              <input className="form-input" name="subject" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-field">
              <label className="form-label" htmlFor="message">
                MESSAGE<span className="form-required">*</span>
              </label>
              <textarea className="form-input form-textarea" name="message" />
            </div>
          </div>
          <div className="form-row">
            <button className="form-button">Send Message</button>
          </div>
        </form>
      </section>
    </div>
  );
}
